var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "addForm",
      staticClass: "demo-form dialog-form",
      attrs: {
        model: _vm.addForm,
        rules: _vm.rules,
        "label-width": "82px",
        "label-position": "left"
      }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "角色名称：", prop: "roleName" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入角色名称" },
            model: {
              value: _vm.addForm.roleName,
              callback: function($$v) {
                _vm.$set(
                  _vm.addForm,
                  "roleName",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "addForm.roleName"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "所属公司：", prop: "companyId" } },
        [
          _c("companySelect", {
            ref: "companySelect",
            attrs: {
              clearable: true,
              companyTree: _vm.companyList,
              valueName: _vm.valueNameAdd,
              value: _vm.valueAdd
            },
            on: { getValue: _vm.getAddGroupId }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "系统：", prop: "systems" } },
        [
          _c(
            "el-select",
            {
              attrs: { clearable: "", multiple: "", placeholder: "请选择系统" },
              model: {
                value: _vm.addForm.systems,
                callback: function($$v) {
                  _vm.$set(_vm.addForm, "systems", $$v)
                },
                expression: "addForm.systems"
              }
            },
            _vm._l(_vm.menuLabelList, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "业务类型：", prop: "businessType" } },
        [
          _c(
            "el-select",
            {
              attrs: {
                clearable: "",
                multiple: "",
                placeholder: "请选择业务类型"
              },
              model: {
                value: _vm.addForm.businessType,
                callback: function($$v) {
                  _vm.$set(_vm.addForm, "businessType", $$v)
                },
                expression: "addForm.businessType"
              }
            },
            _vm._l(_vm.businessTypeList, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.businessName, value: item.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "备注：", prop: "remark" } },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              autosize: { minRows: 2 },
              maxlength: "50",
              "show-word-limit": "",
              placeholder: "请输入内容"
            },
            model: {
              value: _vm.addForm.remark,
              callback: function($$v) {
                _vm.$set(
                  _vm.addForm,
                  "remark",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "addForm.remark"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }